<template>
  <div class="d-flex flex-column px-6 py-6">
    <div class="d-flex indicator-container">
      <div
        v-for="index in 3"
        class="d-flex align-center indicator-box"
        :key="index"
      >
        <v-skeleton-loader type="chip" class="indicator" />
        <v-skeleton-loader type="text, header" class="text" />
      </div>
    </div>
    <div class="d-flex flex-column pt-8">
      <div class="d-flex flex-column chart-column">
        <div class="d-flex align-center">
          <v-skeleton-loader type="text" class="x-label" />
          <v-skeleton-loader type="text" class="x" />
        </div>
        <div class="d-flex align-center">
          <v-skeleton-loader type="text" class="x-label" />
          <v-skeleton-loader type="text" class="x" />
        </div>
        <div class="d-flex align-center">
          <v-skeleton-loader type="text" class="x-label" />
          <v-skeleton-loader type="text" class="x" />
        </div>
        <div class="d-flex align-center">
          <v-skeleton-loader type="text" class="x-label" />
          <v-skeleton-loader type="text" class="x" />
        </div>
        <div class="d-flex align-center">
          <v-skeleton-loader type="text" class="x-label" />
          <v-skeleton-loader type="text" class="x" />
        </div>
        <div class="d-flex align-center">
          <v-skeleton-loader type="text" class="x-label" />
          <v-skeleton-loader type="text" class="x" />
        </div>
      </div>
      <div class="d-flex justify-space-between align-center ml-12 mr-2 mt-1">
        <v-skeleton-loader type="text" class="y-label" />
        <v-skeleton-loader type="text" class="y-label" />
        <v-skeleton-loader type="text" class="y-label" />
        <v-skeleton-loader type="text" class="y-label" />
        <v-skeleton-loader type="text" class="y-label" />
        <v-skeleton-loader type="text" class="y-label" />
        <v-skeleton-loader type="text" class="y-label" />
        <v-skeleton-loader type="text" class="y-label" />
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.illustration {
  min-width: 100%;
  max-width: 100%;
  min-height: 200px;
}

.v-skeleton-loader {
  max-width: 100%;
  overflow: hidden;
  &.indicator::v-deep {
    margin-right: 8px;
    .v-skeleton-loader__chip {
      width: 24px;
      height: 24px;
      margin: 4px;
      border-radius: 3px;
    }
  }
  &.text::v-deep {
    display: flex;
    justify-content: center;
    flex-direction: column;
    .v-skeleton-loader__text {
      width: 70px;
      height: 6px;
      margin: 6px 0;
      border-radius: 3px;
      margin-bottom: 10px;
    }
    .v-skeleton-loader__header {
      width: 86px;
      height: 20px;
      border-radius: 4px;
    }
  }

  &.x::v-deep {
    width: 100%;
    .v-skeleton-loader__text {
      height: 1px;
      margin-left: 8px;
      margin-bottom: 0px;
    }
  }
  &.x-label::v-deep {
    .v-skeleton-loader__text {
      width: 24px;
      height: 8px;
      margin: 6px 10px 6px 0;
    }
  }
  &.y-label::v-deep {
    .v-skeleton-loader__text {
      width: 24px;
      height: 6px;
      margin: 6px 8.5px 12px;
    }
  }
}
.chart-column {
  justify-content: space-between;
  min-height: 200px;
}

.indicator-container {
  max-width: 100%;
  min-width: unset;
  width: 538px;
  justify-content: space-between;
  flex-wrap: wrap;
}
</style>