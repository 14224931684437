const ChartDataNormalizer = {
    methods: {
        removeNulledData: function (dataset) {
            return dataset.map(item => {
                if (item === null || item === undefined) {
                    return 0
                } else {
                    return item
                }
            })
        },
        normalizeChartData: function (chartData) {
            if (!chartData.datasets || !chartData.labels || !chartData.datasets.length || !chartData.labels.length) {
                return {
                    datasets: [[0, 0]],
                    labels: [' ', ' ']
                }
            }

            let newDatasets = chartData.datasets.map(dataset => {
                return this.removeNulledData(dataset)
            })

            let newChartData = { ...chartData, datasets: newDatasets }

            return newChartData
        }
    }

}

export default ChartDataNormalizer